<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		indexGame?: number;
	}>(),
	{ indexGame: 0 }
);

const isLargeNumber = computed(() => props.indexGame >= 9);
</script>

<template>
	<div class="game-image-wrapper">
		<NuxtIcon name="64/top-game-tag" filled class="game-number-tag" />
		<AText :class="['game-number', { 'large-number': isLargeNumber }]">
			{{ indexGame + 1 }}
		</AText>
	</div>
</template>

<style scoped lang="scss">
.game-image-wrapper {
	position: absolute;
	border-radius: 10px;
	overflow: hidden;
	width: 60px;
	height: 60px;
	top: -9px;
	left: 96px;

	.game-number-tag {
		position: absolute;
		right: -4px;
		top: -1px;
		z-index: 2;
		font-size: 60px;
	}
	.game-number {
		position: absolute;
		color: #1e1e1e;
		left: 26px;
		text-align: center;
		font-feature-settings:
			"liga" off,
			"clig" off;
		font-family: Rubik;
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
		text-transform: uppercase;
		z-index: 4;
	}

	.large-number {
		left: 16px;
	}
}
</style>
